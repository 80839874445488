import config from 'config'
import utils from 'utils'
import classes from 'dom-classes'
import Default from './default'

import Swiper from './swiper'

class Directors extends Default {
	
	constructor(opt) {
		
		super(opt);

		this.slug = 'directors'
	}
	
	init(req, done) {

		super.init(req, done)
	
	}
	
	dataAdded(done) {

		super.dataAdded()

		done()
	}

	animateIn(req, done) {

		classes.add(config.$body, `is-${this.slug}`)

		TweenLite.to(this.page, 1, {
			y: 0,
			autoAlpha: 1,
			ease: Expo.easeInOut,
			onComplete: done
		})
		TweenLite.to('footer', 1, { opacity: 1, delay: 1})

		if(document.querySelector('.swiper-container')){
			var swiper = new Swiper('.swiper-container', {
		        pagination: '.swiper-pagination',
		        direction: 'vertical',
		        slidesPerView: 1,
		        parallax: false,
		        loop: true,
		        mousewheelSensitivity: 3,
		        keyboardControl: true,
		        speed: 800,
		        hashnav: true,
		        //effect: 'fade',
		        paginationClickable: true,
		        spaceBetween: 0,
		        mousewheelControl: true
		    });
		}

		var directors = document.querySelectorAll('.director'),
			imgContainer = document.querySelector('.image');

		if (imgContainer) {
			[].forEach.call(directors, function(el){
				el.addEventListener('mouseenter', function(){
					imgContainer.style.backgroundImage = "url("+ this.dataset.bgimg +")";
				});
				el.addEventListener('mouseleave', function(){
					imgContainer.style.backgroundImage = "";
				})
			})
		}


		var container = document.querySelector('.page'),
			footer = document.querySelector('footer');
        container.addEventListener('scroll', function(){
        	
			var scrollTarget = container.scrollTop,
				scrollLenght = container.scrollHeight - window.innerHeight;
			
			if(scrollTarget >= 5){
				footer.classList.remove('hidden');
			}else{
				footer.classList.add('hidden');
			}
			if(scrollTarget >= scrollLenght){
				footer.classList.add('indexUp');
			}else{
				footer.classList.remove('indexUp');
			}
		})

		 var popup = document.getElementById('popup_video'),
		 	 popTile = document.querySelector('.pop-title');
		 var closeP = document.querySelector('.js-close');
         var popers = document.querySelectorAll('.imgWrapper');
  		  var video = document.getElementById('video');

  //       function addSourceToVideo(element, src, type) {
		//     var source = document.createElement('source');

		//     source.src = src;
		//     source.type = type;

		//     element.appendChild(source);
		// }
		

    	[].forEach.call(popers, function(el){
			el.addEventListener('click', function(){
				popup.classList.add('on');
				video.src= "https://player.vimeo.com/video/"+this.dataset.video+"?default_size=0" ;
				popTile.innerHTML = this.dataset.title;
				//addSourceToVideo(video, this.dataset.video, 'video/mp4');

				//video.play();
			})
		})

		closeP.addEventListener('click', function(){
			popup.classList.remove('on');
			//video.pause();
			video.src="";
			popTile.innerHTML = "";
			// while (video.firstChild) {
			//   video.removeChild(video.firstChild);
			// }
		});
				
	}

	animateOut(req, done) {

		classes.remove(config.$body, `is-${this.slug}`)
		var footer = document.querySelector('footer');
			footer.classList.remove('indexUp');
			footer.classList.add('hidden');
		TweenLite.to('footer', 1, { opacity: 0})
		TweenLite.to(this.page, 0.7, {
			y: 0,
			autoAlpha: 0,
			ease: Expo.easeInOut,
			onComplete: done
		})
	}

	destroy(req, done) {

		super.destroy()

		this.page.parentNode.removeChild(this.page)
		
		done()
	}
}

module.exports = Directors