import config from 'config'
import classes from 'dom-classes'
import create from 'dom-create-element'
import gsap from 'gsap'

TweenLite.defaultEase = Expo.easeOut

class Preloader {
	
	constructor(onComplete) {
		
		this.preloaded = onComplete
		this.view = config.$view
		this.el = null

		this.isMobile = config.isMobile = config.width <= 1024 ? true : false
	}
	
	init(req, done) {

		classes.add(config.$body, 'is-loading')
        	
		this.createDOM()

		done()
	}
	
	createDOM() {
		
		const page = this.view.firstChild

		this.el = create({
			selector: 'div',
			styles: 'preloader',
			html: '<div class="vertical-center"><div class="vertical-el"><p><img src="/images/TAT_logos-01.svg" alt=""></p></div></div>'
		})

		this.view.insertBefore(this.el, page)
	}

	resize(width, height) {

		config.width = width
		config.height = height
	}

	animateIn(req, done) {

		const tl = new TimelineMax({ paused: true, onComplete: () => {
			done()
			// call this.preloaded to bring the first route
			this.preloaded()
		}});
		TweenLite.to('header', 0, {opacity:0})
		TweenLite.to('footer', 0, {opacity:0})
		var menuIcon = document.querySelector('.js-menu');
	        var menu = document.getElementById('menu');
	        
	        var menuItem = document.querySelectorAll('#menu .nav-item'), i;

	        menuIcon.addEventListener('click', function(){
	            menu.classList.toggle('on')
	        })
	        menu.addEventListener('click', function(){
	            menu.classList.toggle('on')  
        	})

		// 	var supportsVideo = !!document.createElement('video').canPlayType;

		//   if (supportsVideo) {
		//   	// Obtain handles to main elements
		//   	var videoContainer = document.getElementById('videoContainer');
		//    	var video = document.getElementById('video');
		//    	var videoControls = document.getElementById('video-controls');
		//    	// Hide the default controls
		//    	video.controls = false;
		//    	// Display the user defined video controls
		//    	videoControls.style.display = 'none';
		//    	//video.play()

		//   }

		// document.body.click();


		tl.to(this.el, 1, {delay: 1, autoAlpha: 1})
		tl.restart()
	}
	
	animateOut(req, done) {

		const tl = new TimelineMax({ paused: true, onComplete: done })
		tl.to(this.el, 1, {autoAlpha: 0, delay: 0})
		tl.restart()
		TweenLite.to('header', 1, {delay:1,opacity:1})
		TweenLite.to('footer', 1, {delay:2,opacity:1})
		var footer = document.querySelector('footer');
			footer.classList.remove('indexUp');
			footer.classList.add('hidden');
	}

	destroy(req, done) {

		classes.add(config.$body, 'is-loaded')
		classes.remove(config.$body, 'is-loading')

		this.view.removeChild(this.el)

		done()
	}
}

module.exports = Preloader