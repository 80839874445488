import config from 'config'
import utils from 'utils'
import classes from 'dom-classes'
import Default from './default'
import Flickity from 'flickity'

class Home extends Default {
	
	constructor(opt) {
		
		super(opt)

		this.slug = 'home'
		this.ui = null
	}
	
	init(req, done) {

		super.init(req, done)
	}

	dataAdded(done) {

		super.dataAdded()

		done()
	}

	animateIn(req, done) {

		classes.add(config.$body, `is-${this.slug}`)
		
		TweenLite.to(this.page, 1, {
			y: 0,
			autoAlpha: 1,
			ease: Expo.easeInOut,
			onComplete: done
		})

		TweenLite.to('footer', 1, { opacity: 1, delay: 1})

		 var popup = document.getElementById('popup_video'),
		 	 popTile = document.querySelector('.pop-title');
		 var closeP = document.querySelector('.js-close');
         var popers = document.querySelectorAll('.imgWrapper');
  		  var video = document.getElementById('video');

  //       function addSourceToVideo(element, src, type) {
		//     var source = document.createElement('source');

		//     source.src = src;
		//     source.type = type;

		//     element.appendChild(source);
		// }
		

    	[].forEach.call(popers, function(el){
			el.addEventListener('click', function(){
				popup.classList.add('on');
				video.src= "https://player.vimeo.com/video/"+this.dataset.video+"?default_size=0&autoplay=1" ;
				popTile.innerHTML = this.dataset.title;
				//addSourceToVideo(video, this.dataset.video, 'video/mp4');

				//video.play();
			})

			var lil_vid = el.querySelector('video');

			if (lil_vid){
				el.addEventListener('mouseenter', function(){
					lil_vid.loop = true;
					lil_vid.play();

				})
				el.addEventListener('mouseleave', function(){
					//var lil_vid = this.querySelector('video');
					lil_vid.pause();
				})
			}

		})

		closeP.addEventListener('click', function(){
			popup.classList.remove('on');
			//video.pause();
			video.src="";
			popTile.innerHTML = "";
			// while (video.firstChild) {
			//   video.removeChild(video.firstChild);
			// }
		});
	
		////////
		var header = document.querySelector('header'),
			container = document.querySelector('.page'),
			scrollto = document.querySelector('.scrollDown'),
			footer = document.querySelector('footer');

		header.classList.add('white');
		footer.classList.add('hidden');
        


        container.addEventListener('scroll', function(){
        	
			var scrollTarget = container.scrollTop,
				scrollLenght = container.scrollHeight - window.innerHeight;
				
			if(scrollTarget <=  window.innerHeight){
				header.classList.add('white');
			}else{
				header.classList.remove('white');
			}
			if(scrollTarget >= 5){
				footer.classList.remove('hidden');
			}else{
				footer.classList.add('hidden');
			}
			if(scrollTarget >= scrollLenght){
				footer.classList.add('indexUp');
			}else{
				footer.classList.remove('indexUp');
			}
		})

		function runScroll() {
		  scrollTo(container, window.innerHeight + 1 , 300);
		  header.classList.remove('white');
		}
		function scrollTo(element, to, duration) {
			if (duration <= 0) return;
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;

			setTimeout(function() {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop == to) return;
					scrollTo(element, to, duration - 10);
			}, 10);
		}
		scrollto.addEventListener("click",runScroll,false)
		
	}

	animateOut(req, done) {
		
		classes.remove(config.$body, `is-${this.slug}`)
		var header = document.querySelector('header');
		header.classList.remove('white');

		var footer = document.querySelector('footer');
			footer.classList.remove('indexUp');
			footer.classList.add('hidden');

		TweenLite.to('footer', 1, { opacity: 0})
		TweenLite.to(this.page, 0.7, {
			y: 0,
			autoAlpha: 0,
			ease: Expo.easeInOut,
			onComplete: done
		})
	}

	destroy(req, done) {

		super.destroy()

		this.ui = null

		this.page.parentNode.removeChild(this.page)
		
		done()
	}
}

module.exports = Home