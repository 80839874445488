import config from 'config'
import utils from 'utils'
import classes from 'dom-classes'
import Default from './default'

class Contact extends Default {
	
	constructor(opt) {
		
		super(opt);

		this.slug = 'contact'
	}
	
	init(req, done) {

		super.init(req, done)
	}
	
	dataAdded(done) {

		super.dataAdded()

		done()
	}

	animateIn(req, done) {

		classes.add(config.$body, `is-${this.slug}`)

		TweenLite.to(this.page, 1, {
			y: 0,
			autoAlpha: 1,
			ease: Expo.easeInOut,
			onComplete: done
		})
		TweenLite.to('footer', 1, { opacity: 1, delay: 1})
		var container = document.querySelector('.page'),
			footer = document.querySelector('footer'),
			header = document.querySelector('header');
			header.classList.add('white');
        container.addEventListener('scroll', function(){
        	
			var scrollTarget = container.scrollTop,
				scrollLenght = container.scrollHeight - window.innerHeight;
		
			if(scrollTarget >= scrollLenght){
				footer.classList.add('indexUp');
			}else{
				footer.classList.remove('indexUp');
			}
		})
	}

	animateOut(req, done) {

		classes.remove(config.$body, `is-${this.slug}`)
		var footer = document.querySelector('footer'),
			header = document.querySelector('header');
			footer.classList.remove('indexUp');
			header.classList.remove('white');
			footer.classList.add('hidden');
		TweenLite.to('footer', 1, { opacity: 0})
		TweenLite.to(this.page, 0.7, {
			y: 0,
			autoAlpha: 0,
			ease: Expo.easeInOut,
			onComplete: done
		})
	}

	destroy(req, done) {

		super.destroy()

		this.page.parentNode.removeChild(this.page)
		
		done()
	}
}

module.exports = Contact