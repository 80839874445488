import config from 'config'
import utils from 'utils'
import classes from 'dom-classes'
import Default from './default'
import Flickity from 'flickity'

class Works extends Default {
  
  constructor(opt) {
    
    super(opt)

    this.slug = 'work'
    this.ui = null
  }
  
  init(req, done) {

    super.init(req, done)
  }

  dataAdded(done) {

    super.dataAdded()

    done()
  }

  animateIn(req, done) {

    classes.add(config.$body, `is-${this.slug}`)
    TweenLite.to('footer', 1, { opacity: 0})
    TweenLite.to(this.page, 1, {
      y: 0,
      autoAlpha: 1,
      ease: Expo.easeInOut,
      onComplete: done
    })
    
    TweenLite.to('footer', 1, { opacity: 1, delay: 1})

    var navbtn = document.querySelectorAll('.js-filter'), i,
        content = document.querySelector('#project-content');


    var options = {
        valueNames: [ 'name', 'born' ]
    };
    var userList = new List('users', options);

    for (i = 0; i < navbtn.length; ++i) {

        navbtn[i].addEventListener('click', function(){
      
          var search  =  document.getElementById('search')
          search.classList.remove('on')
          search.value = ''
          
          document.querySelector('.searchTrigger').classList.remove('on')

          document.querySelector('.is-checked').classList.remove('is-checked')
          this.classList.add('is-checked')
          
          var match = this.dataset.filter
          //console.log(match)
          var project = document.querySelectorAll('.work'), i;
          [].forEach.call(project, function(el) {
              el.classList.add('fade')
            setTimeout(function(){
              el.classList.add('none')
            },300)
            if( el.classList.contains(match)){
              setTimeout(function(){
                el.classList.remove('none')
              },300)
              setTimeout(function(){
                el.classList.remove('fade')
              },400)
            }
            if ( match === "*") {
              setTimeout(function(){
                el.classList.remove('none')
              },300)
              setTimeout(function(){
                el.classList.remove('fade')
              },400)
            }
        })
      })
    }

    document.querySelector('.searchTrigger').addEventListener('click', function(){
      document.getElementById('search').classList.toggle('on')
      this.classList.toggle('on')  
    })
    var header = document.querySelector('header'),
      container = document.querySelector('.page'),
      footer = document.querySelector('footer');
      header.style.backgroundColor = "transparent"

      footer.classList.add('hidden');
      container.addEventListener('scroll', function(){
        var scrollTarget = container.scrollTop,
          scrollLenght = container.scrollHeight - window.innerHeight;

        if(scrollTarget >= 190){
          header.style.backgroundColor = "#FFF"
        }else{
           header.style.backgroundColor = "transparent"
        }
        if(scrollTarget >= 5){
          footer.classList.remove('hidden');
          
        }else{
          footer.classList.add('hidden');
         
        }
        if(scrollTarget >= scrollLenght){
          footer.classList.add('indexUp');
        }else{
          footer.classList.remove('indexUp');
        }
      })

      var popup = document.getElementById('popup_video'),
          popTile = document.querySelector('.pop-title');
      var closeP = document.querySelector('.js-close');
      var popers = document.querySelectorAll('.imgWrapper');
      var video = document.getElementById('video');

      [].forEach.call(popers, function(el){
        el.addEventListener('click', function(){
          popup.classList.add('on');
          video.src= "https://player.vimeo.com/video/"+this.dataset.video+"?default_size=0" ;
          popTile.innerHTML = this.dataset.title;
        })

        var lil_vid = el.querySelector('video');

        if (lil_vid) {
          el.addEventListener('mouseenter', function(){

            lil_vid.play();
            lil_vid.loop = true;

          })
          el.addEventListener('mouseleave', function(){
            //var lil_vid = this.querySelector('video');
            lil_vid.pause();
          })
        }

      })

      closeP.addEventListener('click', function(){
        popup.classList.remove('on');
        video.src="";
        popTile.innerHTML = "";
      });
  }

  animateOut(req, done) {
    
    classes.remove(config.$body, `is-${this.slug}`)
    var footer = document.querySelector('footer'),
        header = document.querySelector('header');
       header.style.backgroundColor = "";
      footer.classList.remove('indexUp');
      footer.classList.add('hidden');

    TweenLite.to('footer', 1, { opacity: 0})
    TweenLite.to(this.page, 0.7, {
      y: 0,
      autoAlpha: 0,
      ease: Expo.easeInOut,
      onComplete: done
    })
  }

  destroy(req, done) {

    super.destroy()

    this.ui = null

    this.page.parentNode.removeChild(this.page)
    
    done()
  }
}

module.exports = Works